.application-information-container {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 15px;
  font: $title $font-regular;

  .information-step-title {
    font: $h4 $font-regular;
    color: $secondary-color;
  }
}