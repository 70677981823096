.common-white-container.company-profile-container {
  display: grid !important;
  grid-template-columns: 100px calc(50% - 145px) 135px calc(50% - 145px);
  padding: 25px 30px;
  gap: 15px;

  * {
    min-height: 32px;
  }

  > span {
    color: $primary-color;
    display: flex;
    align-items: center;
  }
  > div {
    width: 95%;
    min-width: 170px;
    color: $field-color;
    display: flex;
    align-items: center;
  }

  .upload-container {
    display: flex;
    justify-content: start;
    text-align: center;
    align-items: center;

    a {
      margin: 0px;
      padding: 0px;
      line-height: 30px;
      color: $field-color;
    }
  }

  .user-dp-upload {
    padding: 0 !important;
    max-height: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .delete-btn {
    background-color: transparent;
    border: none;
  }
}
