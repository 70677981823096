.application-company-container {
  display: grid;
  grid-template-columns: 15% calc(35% - 22px) 15% calc(35% - 22px);
  padding: 0;
  gap: 15px;
  font: $subtitle $font-regular;

  > span {
    margin-top: 5px;
    font: $subtitle $font-regular;
    color: $primary-color;
  }

  > div {
    width: 100%;
  }
}

.full-width-input {
  grid-column: 2 / -1; /* Make the Address field span across all columns */
}

.application-entity-name-modal {
  min-height: 30vh;
  max-height: 70vh;
  width: 75vw;
  background-color: white;

  .modal-body {
    max-height: calc(70vh - 80px);
    padding: 0;
    overflow-y: auto;
    overflow-x: auto;

    .application-entity-name-modal-search-records {
      max-height: calc(70vh - 80px);
      overflow: auto;

      .table-class {
        width: calc(100% - 6px);
      }
    }
  }

  table {
    background-color: white !important;

    th {
      padding: 1rem 1.25rem 0.6rem 1.25rem;
      text-align: left;
    }

    th:first-child,
    th:last-child,
    td:first-child,
    td:last-child {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

.application-entity-name-modal-retry-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  font: $subtitle $font-regular;
  color: $primary-color;
}

.application-search-suffix {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: $primary-color;
}

.application-company-step--main-title {
  margin-top: 5px;
  font: $title $font-medium;
  color: $secondary-color;
}
.application-company-step-title {
  grid-column: 1 / span 4;
  margin-top: 5px;
  font: $title $font-medium;
  color: $primary-color;
}

.application-stepper-divider {
  width: 100% !important;
  grid-column: 1 / span 4;
  margin: 10px 0;
  border-top: 1px solid $primary-color-1;
}

.application-input-or {
  position: relative;
}

.application-input-or::after {
  content: 'OR';
  position: absolute;
  bottom: -15px;
  left: calc(50% - 10px);
  font: $text $font-medium;
  color: $primary-color;
}

@media screen and (max-width: 768px) {
  .application-company-container {
    padding: 0;
    grid-template-columns: 20% 1fr;

    > div {
      grid-column: 2 / span 3;
    }

    .application-stepper-divider {
      grid-column: 1 / span 4;
    }
  }
  .application-entity-name-modal {
    max-height: 80vh;
    width: 90vw;

    .modal-header {
      font: $h6 $font-medium;
      padding: 15px 25px;
    }

    table {
      th {
        padding: 1rem 1.25rem 0.6rem 1.25rem;
        text-align: left;
      }

      th:first-child,
      th:last-child,
      td:first-child,
      td:last-child {
        padding-left: 10px;
        padding-right: 10px;
      }

      tr {
        td {
          height: 1.5rem;
        }
      }
    }
  }

  .modal-content {
    min-width: unset !important;
  }
}

// Mobile Responsive Design
@media screen and (min-width: 320px) and (max-width: 425px) {
  .application-company-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0;

    > span {
      margin-top: 5px;
      font: $subtitle $font-regular;
      color: $primary-color;
    }

    > div {
      width: 100%;
    }
  }
}
