.common-white-container {
  .credit-application-wrapper {
    display: flex;
    gap: 10px;

    .credit-application-qr-wrapper {
      display: flex;
      align-items: start;
    }

    .download-btn {
      height: 40px;
      width: 40px;
      margin-top: 20px;
    }

    .credit-application-link-wrapper {
      padding-top: 20px;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .credit-application-link-label {
        gap: 5px;
        font: $title $font-regular;
        color: $primary-color;
      }
      .credit-application-link {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;

        .public-link {
          max-width: 600px;
          font: $subtitle $font-regular;
          color: $field-color !important;
          text-decoration: underline;
          cursor: pointer;
          overflow: hidden;
          white-space: wrap;
          text-overflow: ellipsis;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #ffffff;
          border: 0.063rem solid $border-color;
          border-radius: 0.313rem;
          padding: 8px;
          text-decoration: none;
        }

        .copy-btn,
        .share-btn {
          height: 30px;
          width: 30px;
          .material-icons-round {
            font-size: 18px;
          }
        }
      }
    }
  }
}
