.stepper-container {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: $primary-color-1;
  border-radius: 10px;

  .arrow {
    color: $placeholder-color;
  }
}

.step-container {
  display: flex;
  align-items: center;
}

.step-container:first-child .arrow {
  display: none;
}

.step {
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 15px;
  font: $title $font-medium;
  color: $placeholder-color;
  border-radius: 10px;
  overflow: hidden;

  span {
    font-weight: 400;
    margin-right: 10px;
    color: $placeholder-color;
  }
}

.selected-step {
  color: white;
  background-color: $primary-color;
}

.done-step {
  .step {
    background-color: $success-color;
    color: white;
  }

  span {
    color: white;
  }

  .arrow {
    color: $primary-color;
  }
}

.step-container.active-step {
  .step {
    color: white;
    background-color: $primary-color;

    span {
      color: white;
    }
  }

  .arrow {
    color: $primary-color;
  }
}

.step-container.active-step.hidden {
  display: none;

  + .step-container {
    .arrow {
      display: none;
    }
  }
}

.done-step.hidden {
  display: none;

  + .step-container {
    .arrow {
      display: none;
    }
  }
}

.step-content {
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.stepper-buttons-row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.step-content-name {
  display: none;
}

.stepper-container.public {
  overflow-x: hidden;
  .step {
    padding: 10px;
    margin-right: 0;

    .material-icons-round {
      font-size: 20px;
    }

    .step-name {
      margin-right: 0;
    }
  }
}

.step-content-wrapper.public {
  .step-content {
    position: relative;

    .loader-container {
      position: absolute;
    }

    .application-company-container {
      .application-stepper-divider:first-child {
        border-top: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .stepper-container.public {
    .step {
      padding: 10px;
      margin-right: 0;

      span {
        margin-right: 0;
      }

      .step-name {
        display: none;
      }
    }
  }
  .step-content-wrapper {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;

    .step-content {
      width: 100%;
      margin-top: 0;
      padding: 0;
      border-radius: 0;
    }

    .step-content-name {
      display: block;
      font: $h6 $font-medium;
      color: $primary-color;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .stepper-container.public {
    .step {
      padding: 10px;
      margin-right: 0;
      display: flex;
      flex-direction: column;

      span {
        margin-right: 0;
      }

      .step-name {
        display: flex;
        text-align: center;
      }
    }
  }

  .step-content-wrapper {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    .step-content {
      width: 100%;
      margin-top: 0;
      padding: 0;
      border-radius: 0;
    }

    .step-content-name {
      display: block;
      font: $h6 $font-medium;
      color: $primary-color;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 375px) {
  .stepper-container.public {
    overflow-x: scroll;
  }
}
