.public-layout-container {
  height: 100vh;
  width: 100%;
  padding: 1rem 10%;
  background-color: $background-color;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .public-company-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font: $h4 $font-bold;
    color: $primary-color;

    .subtitle{
      font: $title $font-regular;
      color: $field-color;
    }
  }

  .copany-logo {
    position: relative;
    height: 3.5rem;
    width: 3.5rem;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .breadcrumb-button-row {
    display: none;
  }

  .success-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .material-icons-round{
      font-size: 80px;
      color: $success-variant-color;
    }

    .title{
      font: $h6 $font-medium;
      // color: $success-color
    }

    .message {
      width: 80%;
      font: $title $font-regular;
      text-align: justify;
    }
  }
}

@media screen and (max-width: 768px) {
  .public-layout-container {
    padding: 1rem;

    .breadcrumb {
      font: $title $font-medium;
    }

  .button {
    padding: 6px 12px;
    border-radius: 8px;
  }
  .message {
    width: 100%;
  }
}
}
